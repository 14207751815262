<template>
    <div>
        <div class="banner" style="width: 100%; height: auto">
            <img :src="Src_" alt="" style="width:100%; height:100%;">
        </div>
        <div class="main">
            <div>
                <img src="../assets/pic/t1.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t2.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t3.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t4.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t5.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t6.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t7.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t8.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t9.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t10.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t11.jpg" alt="">
            </div>
            <div>
                <img src="../assets/pic/t12.jpg" alt="">
            </div>
            <div>
                <img src="../assets/pic/t13.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t14.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t15.jpg" alt="">
            </div>
            <div>
                <img src="../assets/pic/t16.jpg" alt="">
            </div>
            <div>
                <img src="../assets/pic/t17.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t18.jpg" alt="">
            </div>
            <div>
                <img src="../assets/pic/t19.png" alt="">
            </div>
            <div>
                <img src="../assets/pic/t20.png" alt="">
            </div>
            <div style="width:500px; box-shadow: 0 0 0 0;">
                <!-- <img src="../assets/pic/t20.png" alt=""> -->
            </div>
        </div>
        <div style="width: 100%; height:100px;"></div>
        <Footer></Footer>
    </div>
</template>
<script>
import Footer from '../views/footer'
export default{
    components:{Footer},
    computed: {
        Src_() {
            let w = document.body.clientWidth;
            if (w > 600) {
                return require('../assets/ai.png')
            } else {
                return require('../assets/ai_2.png')
            }
        },
    }
}
</script>
<style scoped>
.main{
    width: 100%;
    display: flex;
    justify-content: center;
    /* flex-direction: column; */
    flex-wrap: wrap;
    margin-top: 40px;
}
.main div{
    margin: 10px;
    /* width:250px; */
    height:200px;
    /* scale: .5; */
    box-shadow: 1px 1px 5px 1px #ccc;
    /* padding: 20px; */
}
.main div>img{
    /* width: 100%;
    height: 100%; */
}
@media screen and (max-device-width: 800px) {
    .main div{
        margin: 10px;
        width: 80%;
        height: auto;
        box-shadow: 1px 1px 5px 1px #ccc;
        padding: 10px;
        display: flex;
    }
    .main div>img{
        width: 100% !important;
    }
    .banner{
        height: auto !important;
    }
}
</style>